export default function HiddenPhone() {
    const btns = document.querySelectorAll('.hidden-phone') as NodeListOf<HTMLAnchorElement>

    btns?.forEach(btn => {
        btn.onclick = () => {
            const number = btn.href.replace('tel:', '').match(/.{1,2}/g)
            if (!number) return
            btn.innerText = number.join('')
        }
    })
}
