export default function storeLocator() {
    initAutocomplete();
    initMap();

    function initAutocomplete() {
        const form = document.querySelector(".store-locator__form");
        const input = document.querySelector(`input[name="location"]`) as any;

        if (form && input) {
            let options = {
                types: ["(regions)"],
                componentRestrictions: {country: "fr"},
            };

            const searchBox = new google.maps.places.SearchBox(input);

            // Listen for the event fired when the user selects an item from the
            // pick list. Retrieve the matching places for that item.
            google.maps.event.addListener(
                searchBox,
                "places_changed",
                function () {
                    // @ts-ignore
                    var place = searchBox.getPlaces()[0];

                    if (!place.geometry) return;

                    if (place.geometry.location) {
                        const coordinates_input = document.querySelector(
                            `input[name="coordinates"]`
                        ) as any;
                        coordinates_input.setAttribute(
                            "value",
                            place.geometry.location.lat() +
                            "," +
                            place.geometry.location.lng()
                        );
                    }

                    if (place.address_components) {
                        let city = {} as any;
                        for (
                            let i = 0;
                            i < place.address_components.length;
                            i++
                        ) {
                            if (
                                place.address_components[i].types[0] ==
                                "locality"
                            ) {
                                city = place.address_components[i];
                            }
                        }

                        // Set city in location input.
                        if (city.long_name) {
                            const location_input = document.querySelector(
                                `input[name="location"]`
                            ) as any;
                            location_input.setAttribute(
                                "value",
                                city.long_name
                            );
                        }
                    }
                }
            );

            if (form instanceof HTMLFormElement) {
                form.onsubmit = (e) => {
                    e.preventDefault();

                    const target = e.target as HTMLFormElement;
                    if (target) {
                        setTimeout(() => {
                            target.submit();
                        }, 1000);
                    }
                };
            }
        }
    }

    /*
     * Init map.
     */
    function initMap() {
        // Create map.
        const map = createMap();

        // Create LatLngBounds object.
        const latlngbounds = new google.maps.LatLngBounds();

        const coopList = Object.values(window.drupalSettings.coopList);
        if (coopList.length > 0) {
            const markers = [] as any;
            const bounds = new google.maps.LatLngBounds();

            coopList.forEach((site: any) => {
                // Create markers.
                createMarker(site, map, markers, bounds, latlngbounds);
            });

            // Click event listeners to geolocate user.
            const locate_me = document.querySelector(
                `button.locate-me`
            ) as HTMLElement;
            locate_me.addEventListener("click", function () {
                geolocate();
            });

            // Click on map filters.
            const map_filter = document.querySelectorAll(
                `input[name="map-filter"]`
            ) as NodeListOf<Element>;
            for (const filter of map_filter as any) {
                filter.addEventListener("click", function () {
                    filterLegendMap(map_filter, markers, map);
                });
            }
        }

        const locationInput = window.drupalSettings.locationInput as any | null;
        const coordinatesInput = window.drupalSettings.coordinatesInput as
            | any
            | null;

        if (locationInput) {
            let input = document.querySelector(
                'input[name="location"]'
            ) as HTMLElement;
            input.setAttribute("value", locationInput);

            // Zoom on the map if there is a search by location.
            const locationLatLng = window.drupalSettings.locationLatLng as
                | any
                | null;
            if (locationLatLng != null) {
                map.setCenter(
                    new google.maps.LatLng({
                        lat: Number(locationLatLng[0]),
                        lng: Number(locationLatLng[1]),
                    })
                );
                map.setZoom(8);
            }

            if (coordinatesInput) {
                let input_test = document.querySelector(
                    'input[name="coordinates"]'
                ) as HTMLElement;
                input_test.setAttribute("value", coordinatesInput);
            }
        } else {
            // Center map and adjust Zoom based on the position of all markers.
            map.setCenter(latlngbounds.getCenter());
            map.fitBounds(latlngbounds);
        }

        /*
         * Create map.
         */
        function createMap() {
            // Create an array of styles.
            const mapStyles = [
                {
                    featureType: "administrative",
                    elementType: "labels.text.fill",
                    stylers: [
                        {
                            color: "#1E1E1E",
                        },
                    ],
                },
                {
                    featureType: "administrative",
                    elementType: "geometry.fill",
                    stylers: [{visibility: "off"}],
                },
                {
                    featureType: "landscape",
                    elementType: "all",
                    stylers: [
                        {
                            color: "#E4E5E5",
                        },
                    ],
                },
                {
                    featureType: "landscape.man_made",
                    elementType: "geometry.fill",
                    stylers: [
                        {
                            color: "#828282",
                        },
                        {
                            lightness: "36",
                        },
                    ],
                },
                {
                    featureType: "poi",
                    elementType: "all",
                    stylers: [
                        {
                            visibility: "simplified",
                        },
                    ],
                },
                {
                    featureType: "road",
                    elementType: "all",
                    stylers: [
                        {
                            saturation: -100,
                        },
                        {
                            lightness: 45,
                        },
                    ],
                },

                {
                    featureType: "water",
                    elementType: "all",
                    stylers: [
                        {
                            color: "#C3C4C6",
                        },
                        {
                            visibility: "on",
                        },
                    ],
                },
                {featureType: "poi", stylers: [{visibility: "off"}]},
                {featureType: "transit", stylers: [{visibility: "off"}]},
            ];

            const center: google.maps.LatLngLiteral = {
                lat: 46.71109,
                lng: 1.7191036,
            };
            const mapOptions = {
                center,
                zoom: 6,
                styles: mapStyles,
                mapTypeControl: false,
            };

            return new google.maps.Map(
                document.getElementById("store-locator-map") as HTMLElement,
                mapOptions
            );
        }

        /*
         * Create marker.
         */
        function createMarker(site: any, map: any, markers: any, bounds: any, latlngbounds: any) {
            const types = ['artisan', 'exhibition_hall'];

            if (site.coordonnees_gps && types.includes(site.bundle) && site.coordonnees_gps != "0,0" && site.coordonnees_gps != ",") {
                const coordinates = site.coordonnees_gps.split(",");
                const lat = Number(coordinates[0]);
                const lng = Number(coordinates[1]);
                const position = {lat: lat, lng: lng};

                // Paramètre de icons
                let svg = "";
                if (site.bundle == "artisan") {
                    svg = window.btoa(`
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="icon24/pin_user">
                            <path id="Vector"
                                  d="M24 13.3335C24 18.0002 16 26.6668 16 26.6668C16 26.6668 8 18.0002 8 13.3335C8 8.91683 11.5833 5.3335 16 5.3335C20.4167 5.3335 24 8.91683 24 13.3335Z"
                                  fill="#C7352D"/>
                            <g id="person_pin_circle">
                                <path d="M18.3202 16.7679C17.6248 17.145 16.8514 17.3335 16 17.3335C15.1486 17.3335 14.3752 17.145 13.6798 16.7679C12.9843 16.3908 12.4244 15.8916 12 15.2702C12.5564 14.8696 13.1739 14.5643 13.8525 14.3543C14.5311 14.1444 15.247 14.0394 16 14.0394C16.753 14.0394 17.4689 14.1444 18.1475 14.3543C18.8262 14.5643 19.4436 14.8696 20 15.2702C19.5756 15.8916 19.0157 16.3908 18.3202 16.7679Z"
                                      fill="white"/>
                                <path d="M17.2508 12.147C16.9073 12.4674 16.4904 12.6276 16 12.6276C15.5096 12.6276 15.0927 12.4674 14.7492 12.147C14.4056 11.8267 14.2339 11.4379 14.2339 10.9805C14.2339 10.5232 14.4056 10.1344 14.7492 9.81404C15.0927 9.49368 15.5096 9.3335 16 9.3335C16.4904 9.3335 16.9073 9.49368 17.2508 9.81404C17.5944 10.1344 17.7661 10.5232 17.7661 10.9805C17.7661 11.4379 17.5944 11.8267 17.2508 12.147Z"
                                      fill="white"/>
                            </g>
                        </g>
                    </svg>
                    `);
                } else if (site.bundle == "exhibition_hall") {
                    svg = window.btoa(`
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="icon24/pin_star">
                            <path id="Vector"
                                  d="M24 13.3335C24 18.0002 16 26.6668 16 26.6668C16 26.6668 8 18.0002 8 13.3335C8 8.91683 11.5833 5.3335 16 5.3335C20.4167 5.3335 24 8.91683 24 13.3335Z"
                                  fill="#003160"/>
                            <path id="storefront"
                                  d="M13.1697 16.3335C12.9638 16.3335 12.7894 16.2635 12.6468 16.1235C12.5041 15.9835 12.4327 15.8125 12.4327 15.6104V12.415C12.2686 12.2827 12.1454 12.1111 12.063 11.9C11.9807 11.689 11.979 11.4612 12.0579 11.2166L12.4703 9.89503C12.5247 9.72683 12.617 9.59119 12.7471 9.48811C12.8773 9.38503 13.0328 9.3335 13.2136 9.3335H18.7786C18.9594 9.3335 19.1139 9.38273 19.2419 9.48119C19.37 9.57965 19.4633 9.71503 19.5218 9.88734L19.9421 11.2166C20.021 11.4612 20.0193 11.6882 19.937 11.8977C19.8546 12.1072 19.7314 12.2822 19.5673 12.4227V15.6104C19.5673 15.8125 19.4959 15.9835 19.3533 16.1235C19.2106 16.2635 19.0363 16.3335 18.8303 16.3335H13.1697ZM16.8969 12.1335C17.1196 12.1335 17.287 12.0667 17.3991 11.9331C17.5112 11.7995 17.5571 11.6561 17.5367 11.5027L17.2889 9.93348H16.3058V11.5135C16.3058 11.6817 16.3638 11.8271 16.4798 11.9497C16.5959 12.0722 16.7349 12.1335 16.8969 12.1335ZM15.0623 12.1335C15.25 12.1335 15.4022 12.0722 15.519 11.9497C15.6358 11.8271 15.6942 11.6817 15.6942 11.5135V9.93348H14.7111L14.4633 11.5181C14.4414 11.6602 14.4868 11.7982 14.5997 11.9323C14.7126 12.0665 14.8668 12.1335 15.0623 12.1335ZM13.2481 12.1335C13.3991 12.1335 13.529 12.0818 13.6378 11.9785C13.7465 11.8752 13.8136 11.7453 13.8392 11.5889L14.0792 9.93348H13.2136C13.1692 9.93348 13.1339 9.94309 13.1077 9.96233C13.0816 9.98156 13.062 10.0104 13.0489 10.0489L12.6569 11.3504C12.6031 11.5222 12.6284 11.6957 12.733 11.8708C12.8375 12.0459 13.0092 12.1335 13.2481 12.1335ZM18.7519 12.1335C18.9725 12.1335 19.1413 12.0485 19.2584 11.8785C19.3755 11.7085 19.4037 11.5325 19.3431 11.3504L18.9307 10.0412C18.9176 10.0027 18.898 9.97515 18.8719 9.95848C18.8457 9.94181 18.8105 9.93348 18.766 9.93348H17.9208L18.1608 11.5889C18.1864 11.7453 18.2535 11.8752 18.3623 11.9785C18.471 12.0818 18.6009 12.1335 18.7519 12.1335Z"
                                  fill="white"/>
                        </g>
                    </svg>
                    `);
                }

                let markerIcon = {
                    url: `data:image/svg+xml;base64,${svg}`,
                    scaledSize: new google.maps.Size(42, 42),
                    origin: new google.maps.Point(0, 0),
                };

                // Create marker.
                let marker = new google.maps.Marker({
                    map: map,
                    position: new google.maps.LatLng(position),
                    icon: markerIcon,
                });

                // Set bundle.
                marker.set("bundle", site.bundle);

                // Set orcab_id.
                marker.set("id", site.orcab_id);

                // Click on marker.
                marker.addListener("click", function () {
                    map.setZoom(9);
                    map.setCenter(marker.getPosition());

                    // Scroll until the coop.
                    const coop = document.querySelector(
                        `[data-orcab-id="${site.orcab_id}"]`
                    ) as HTMLElement;

                    const storeLocatorElement = document.querySelector(
                        ".store-locator"
                    ) as HTMLElement;

                    if (storeLocatorElement) {
                        document.documentElement.scrollTop =
                            storeLocatorElement.offsetTop - 80;
                    }

                    if (coop && coop.parentNode) {
                        (coop.parentNode as HTMLElement).scrollTop =
                            coop.offsetTop -
                            (coop.parentNode as HTMLElement).offsetTop;
                    }
                });

                // Click button localisation on the coops list.
                setTimeout(() => {
                    let coop = document.querySelector(
                        `[data-orcab-id="${site.orcab_id}"] .address svg`
                    ) as HTMLElement;
                    if (coop) {
                        coop.addEventListener("click", () => {
                            highlight(site, markers, map);
                        });
                    }
                }, 1000);

                // All markers.
                bounds.extend(position);
                markers.push(marker);

                //Extend each marker's position in LatLngBounds object.
                latlngbounds.extend(position);
            }
        }

        /*
         * Geolocate.
         */
        function geolocate() {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(function (position) {
                    let pos = (position.coords.latitude +
                        "," +
                        position.coords.longitude) as string;

                    // Add city in location input.
                    let geocoder = new google.maps.Geocoder();
                    geocoder.geocode(
                        {address: pos},
                        function (results, status) {
                            if (status == google.maps.GeocoderStatus.OK) {
                                let city: any = {
                                    long_name: undefined,
                                };

                                let geometry: any = "";

                                if (results) {
                                    let resultsNumber =
                                        results.length as number;
                                    let indice = 0;
                                    for (let j = 0; j < resultsNumber; j++) {
                                        if (results[j].types[0] == "locality") {
                                            indice = j;
                                            break;
                                        }
                                    }
                                    for (
                                        let i = 0;
                                        i <
                                        results[indice].address_components
                                            .length;
                                        i++
                                    ) {
                                        if (
                                            results[indice].address_components[
                                                i
                                                ].types[0] == "locality"
                                        ) {
                                            city =
                                                results[indice]
                                                    .address_components[i];

                                            geometry = results[indice].geometry;
                                        }
                                    }

                                    // set city in location input.
                                    const location_input =
                                        document.querySelector(
                                            `input[name="location"]`
                                        ) as any;
                                    location_input.setAttribute(
                                        "value",
                                        city.long_name
                                    );

                                    const coordinates_input =
                                        document.querySelector(
                                            `input[name="coordinates"]`
                                        ) as any;
                                    coordinates_input.setAttribute(
                                        "value",
                                        geometry.location.lat() +
                                        "," +
                                        geometry.location.lng()
                                    );
                                } else {
                                    console.log("No results found");
                                }
                            } else {
                                console.log(
                                    "Geocoder failed due to: " + status
                                );
                            }
                        }
                    );
                });
            }
        }

        /*
         * Highlight coop and site on the map.
         */
        function highlight(site: any, markers: any, map: any) {
            markers.forEach((marker: any) => {
                let svg = "";
                if (marker.bundle == "exhibition_hall") {
                    let color =
                        marker.id == site.orcab_id ? "#003160" : "#7f98af";
                    svg = window.btoa(`
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="icon24/pin_star">
                            <path id="Vector"
                            d="M24 13.3335C24 18.0002 16 26.6668 16 26.6668C16 26.6668 8 18.0002 8 13.3335C8 8.91683 11.5833 5.3335 16 5.3335C20.4167 5.3335 24 8.91683 24 13.3335Z"
                            fill="${color}"/>
                            <path id="storefront"
                            d="M13.1697 16.3335C12.9638 16.3335 12.7894 16.2635 12.6468 16.1235C12.5041 15.9835 12.4327 15.8125 12.4327 15.6104V12.415C12.2686 12.2827 12.1454 12.1111 12.063 11.9C11.9807 11.689 11.979 11.4612 12.0579 11.2166L12.4703 9.89503C12.5247 9.72683 12.617 9.59119 12.7471 9.48811C12.8773 9.38503 13.0328 9.3335 13.2136 9.3335H18.7786C18.9594 9.3335 19.1139 9.38273 19.2419 9.48119C19.37 9.57965 19.4633 9.71503 19.5218 9.88734L19.9421 11.2166C20.021 11.4612 20.0193 11.6882 19.937 11.8977C19.8546 12.1072 19.7314 12.2822 19.5673 12.4227V15.6104C19.5673 15.8125 19.4959 15.9835 19.3533 16.1235C19.2106 16.2635 19.0363 16.3335 18.8303 16.3335H13.1697ZM16.8969 12.1335C17.1196 12.1335 17.287 12.0667 17.3991 11.9331C17.5112 11.7995 17.5571 11.6561 17.5367 11.5027L17.2889 9.93348H16.3058V11.5135C16.3058 11.6817 16.3638 11.8271 16.4798 11.9497C16.5959 12.0722 16.7349 12.1335 16.8969 12.1335ZM15.0623 12.1335C15.25 12.1335 15.4022 12.0722 15.519 11.9497C15.6358 11.8271 15.6942 11.6817 15.6942 11.5135V9.93348H14.7111L14.4633 11.5181C14.4414 11.6602 14.4868 11.7982 14.5997 11.9323C14.7126 12.0665 14.8668 12.1335 15.0623 12.1335ZM13.2481 12.1335C13.3991 12.1335 13.529 12.0818 13.6378 11.9785C13.7465 11.8752 13.8136 11.7453 13.8392 11.5889L14.0792 9.93348H13.2136C13.1692 9.93348 13.1339 9.94309 13.1077 9.96233C13.0816 9.98156 13.062 10.0104 13.0489 10.0489L12.6569 11.3504C12.6031 11.5222 12.6284 11.6957 12.733 11.8708C12.8375 12.0459 13.0092 12.1335 13.2481 12.1335ZM18.7519 12.1335C18.9725 12.1335 19.1413 12.0485 19.2584 11.8785C19.3755 11.7085 19.4037 11.5325 19.3431 11.3504L18.9307 10.0412C18.9176 10.0027 18.898 9.97515 18.8719 9.95848C18.8457 9.94181 18.8105 9.93348 18.766 9.93348H17.9208L18.1608 11.5889C18.1864 11.7453 18.2535 11.8752 18.3623 11.9785C18.471 12.0818 18.6009 12.1335 18.7519 12.1335Z"
                            fill="white"/>
                                </g>
                        </svg>`);
                } else if (marker.bundle == "artisan") {
                    let color =
                        marker.id == site.orcab_id ? "#C7352D" : "#EFBCB9";
                    svg = window.btoa(`
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="icon24/pin_user">
                                <path id="Vector"
                                d="M24 13.3335C24 18.0002 16 26.6668 16 26.6668C16 26.6668 8 18.0002 8 13.3335C8 8.91683 11.5833 5.3335 16 5.3335C20.4167 5.3335 24 8.91683 24 13.3335Z"
                                fill="${color}"/>
                                <g id="person_pin_circle">
                                    <path d="M18.3202 16.7679C17.6248 17.145 16.8514 17.3335 16 17.3335C15.1486 17.3335 14.3752 17.145 13.6798 16.7679C12.9843 16.3908 12.4244 15.8916 12 15.2702C12.5564 14.8696 13.1739 14.5643 13.8525 14.3543C14.5311 14.1444 15.247 14.0394 16 14.0394C16.753 14.0394 17.4689 14.1444 18.1475 14.3543C18.8262 14.5643 19.4436 14.8696 20 15.2702C19.5756 15.8916 19.0157 16.3908 18.3202 16.7679Z"
                                    fill="white"/>
                                    <path d="M17.2508 12.147C16.9073 12.4674 16.4904 12.6276 16 12.6276C15.5096 12.6276 15.0927 12.4674 14.7492 12.147C14.4056 11.8267 14.2339 11.4379 14.2339 10.9805C14.2339 10.5232 14.4056 10.1344 14.7492 9.81404C15.0927 9.49368 15.5096 9.3335 16 9.3335C16.4904 9.3335 16.9073 9.49368 17.2508 9.81404C17.5944 10.1344 17.7661 10.5232 17.7661 10.9805C17.7661 11.4379 17.5944 11.8267 17.2508 12.147Z"
                                    fill="white"/>
                                </g>
                            </g>
                        </svg>`);
                }


                let markerIcon = {
                    url: `data:image/svg+xml;base64,${svg}`,
                    scaledSize: new google.maps.Size(42, 42),
                    origin: new google.maps.Point(0, 0),
                };
                marker.setIcon(markerIcon);

                if (marker.id == site.orcab_id) {
                    marker.setZIndex(100);
                }

                marker.setMap(map);

                if (marker.id == site.orcab_id) {
                    map.setZoom(9);
                    map.setCenter(marker.getPosition());
                }
            });
        }

        /*
         * Filter on the map.
         */
        function filterLegendMap(map_filter: any, markers: any, map: any) {
            let checkCoops = [] as any;
            let typesSite = {
                artisan: "artisan",
                exhibition_hall: "exhibition_hall",
            } as any;
            for (let i = 0; i < map_filter.length; i++) {
                if (map_filter[i].checked) {
                    let id = map_filter[i].id as any;
                    checkCoops.push(typesSite[id] as string);
                }
            }
            markers.forEach((marker: any) => {
                let marker_bundle = marker.bundle as any;
                if (checkCoops.includes(marker_bundle)) {
                    marker.setMap(map);
                } else {
                    marker.setMap(null);
                }
            });
        }
    }
}
