/**
 * Import des librairies
 * ******************************
 */
import "reset-css";

/**
 * Import des styles et des modules
 * ****************************************
 */
import "./../sass/style.scss";

import ShowResponsiveMenu from "./modules/showResponsiveMenu";
import ShowSubMenuItems from "./modules/showSubMenuItems";
import PlayerVideo from "./modules/playerVideo";
import StoreLocator from "./modules/storeLocator";
import StoreLocatorMenuMobile from "./modules/storeLocatorMenuMobile";
import StoreLocatorFilters from "./modules/storeLocatorFilters";
import StoreSwitch from "./modules/storeSwitch";
import HiddenPhone from "./modules/hiddenPhone";

function doOnDocumentLoaded() {
    loaderInit();
}

if (document.readyState === "loading") {
    document.addEventListener("DOMContentLoaded", doOnDocumentLoaded);
} else {
    doOnDocumentLoaded();
}

/**
 * Initialisation des modules
 * ******************************
 */
function loaderInit() {
    ShowResponsiveMenu();
    ShowSubMenuItems();
    PlayerVideo();

    const StoreLocatorElement = document.getElementById(
        "store-locator"
    ) as HTMLElement;
    if (StoreLocatorElement) {
        StoreLocator();
        StoreSwitch();
        StoreLocatorMenuMobile();
        StoreLocatorFilters();
        HiddenPhone();
    }
}
